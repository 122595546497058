
import { mapGetters } from "vuex";
export default {
    name: "SliderComponent",
    data: () => ({
        length: 3,
        sliderVal: 0,
        imageWidth: 0,
        imageHeight: 0,
        carousels: [
            {
                background_image: "/assets/banner-img-1.png",
                top_button: "OFFSHORE",
                title: "Laos Off-shore graming",
                subtitle: "Control Corporation",
                text: "Lao off-shore gaming Control Corporation (LCGCC) is a 100 percent goverment owned and controlled corporation under the Office of the President of the Lao People's Democratic Republic",
                bottom_button: "learn more",
            },
            {
                background_image: "/assets/banner-img-2.png",
                top_button: "OFFSHORE",
                title: "Cambling Business Solution",
                subtitle: "Control Corporation",
                text: "LOGA - Laos office gamuing authority for online casinos and sportsbooks offshore gaming license -- everything your business needs to get going.",
                bottom_button: "learn more",
            },
            {
                background_image: "/assets/banner-img-3.png",
                top_button: "OFFSHORE",
                title: "Cambling Business Solution",
                subtitle: "Control Corporation",
                text: "LOGA - Laos office gamuing authority for online casinos and sportsbooks offshore gaming license -- everything your business needs to get going.",
                bottom_button: "learn more",
            },
        ],
        onboarding: 0,
    }),
    computed: {
        ...mapGetters({
            sliders: "home/sliders",
            loading: "home/loading",
        }),
    },
    methods: {
        onResize() {
            const screenWidth = window.innerWidth;
            // since the slider size is 1920x500, so I have an formula to calculate the size:
            const height = (screenWidth * 500) / 1920;

            this.imageWidth = screenWidth;
            this.imageHeight = Math.round(height);
        },
    },
};
